import { AbilityBuilder, Ability } from '@casl/ability'

export type Subjects = string
export type Actions = 'manage' | 'create' | 'read' | 'update' | 'delete'

export type AppAbility = Ability<[Actions, Subjects]> | undefined

export const AppAbility = Ability as any
export type ACLObj = {
  action: Actions
  subject: string
}

/**
 * Please define your own Ability rules according to your app requirements.
 * We have just shown Admin and Client rules for demo purpose where
 * admin can manage everything and client can just visit ACL page
 */
const defineRulesFor = (role: string, subject: string, type: string) => {
  const { can, cannot, rules } = new AbilityBuilder(AppAbility)

  /*
  ADMIN ROLES (role): 
    1 - Administrator 
    2 - SA
    3 - CC
    4 - Agent
    5 - God
  
  PRE-DEFINED USER TYPES (type):
    Operations
    SA
    CC
    Agent
    God
  */

  const allActions = ['read', 'create', 'update', 'delete']
  can(allActions, 'shared-page')

  const currentRole = role.toLowerCase()
  const currentType = type.toLowerCase()

  if (currentType === 'administrator' || currentType === 'operations' || currentType === 'god') {
    can('manage', 'all')
    cannot('manage', 'cc-only-page')
    cannot('manage', 'agent-only-page')
    cannot('manage', 'chat-page')
  } else if (currentType === 'cc') {
    can(allActions, 'cc-page')
    can(allActions, 'cc-only-page')
    can(allActions, 'cc-god-only-page')
    can(allActions, 'chat-page')
    can('manage', 'except-agent-page')
  } else if (currentType === 'sa') {
    can(allActions, 'sa-page')
    can(allActions, 'agent-page')
    can(allActions, 'cc-page')
    can(allActions, 'chat-page')
    can('manage', 'except-agent-page')
  } else if (currentType === 'agent') {
    can(allActions, 'agent-page')
    can(allActions, 'agent-only-page')
    cannot('manage', 'except-agent-page')
  } else {
    can(allActions, subject)
  }

  return rules
}

export const buildAbilityFor = (role: string, subject: string, type: string): AppAbility => {
  return new AppAbility(defineRulesFor(role, subject, type), {
    // https://casl.js.org/v5/en/guide/subject-type-detection
    // @ts-ignore
    detectSubjectType: object => object!.type
  })
}

export const defaultACLObj: ACLObj = {
  action: 'manage',
  subject: 'all'
}

export default defineRulesFor
