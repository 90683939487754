// ** Types
import { NavGroup, NavLink } from 'src/@core/layouts/types'
import { NextRouter } from 'next/router'

/**
 * Check for URL queries as well for matching
 * Current URL & Item Path
 *
 * @param item
 * @param activeItem
 */
export const handleURLQueries = (router: NextRouter, path: string | undefined): boolean => {
  if (Object.keys(router.query).length && path) {
    const arr = Object.keys(router.query)

    return router.asPath.includes(path) && router.asPath.includes(router.query[arr[0]] as string) && path !== '/'
  }

  return false
}

/**
 * Check if the given item has the given url
 * in one of its children
 *
 * @param item
 * @param currentURL
 */
export const hasActiveChild = (item: NavGroup, currentURL: string): boolean => {
  const { children } = item

  if (!children) {
    return false
  }

  for (const child of children) {
    if ((child as NavGroup).children) {
      if (hasActiveChild(child, currentURL)) {
        return true
      }
    }
    const childPath = (child as NavLink).path

    // Check if the child has a link and is active
    if (
      child &&
      childPath &&
      currentURL &&
      (childPath === currentURL || (currentURL.includes(childPath) && childPath !== '/'))
    ) {
      return true
    }
  }

  return false
}

/**
 * Check if this is a children
 * of the given item
 *
 * @param children
 * @param openGroup
 * @param currentActiveGroup
 */
export const removeChildren = (children: NavLink[], openGroup: string[], currentActiveGroup: string[]) => {
  children.forEach((child: NavLink) => {
    if (!currentActiveGroup.includes(child.title)) {
      const index = openGroup.indexOf(child.title)
      if (index > -1) openGroup.splice(index, 1)

      // @ts-ignore
      if (child.children) removeChildren(child.children, openGroup, currentActiveGroup)
    }
  })
}

export const sortVideoList = (videos: any[], multiple: string[], single?: string[]) => {
  const output = [...videos]
  output.sort((a, b) => {
    const aInSingle = single && single.includes(a._id);
    const bInSingle = single && single.includes(b._id);
    
    const aInMultiple = multiple.includes(a._id);
    const bInMultiple = multiple.includes(b._id);
    
    // Sort by appearance in 'single' first
    if (aInSingle && !bInSingle) return -1;
    if (!aInSingle && bInSingle) return 1;
    
    // If neither or both are in 'single', sort by order in 'multiple'
    if (aInMultiple && bInMultiple) {
      return multiple.indexOf(a._id) - multiple.indexOf(b._id);
    }
    
    // Sort by 'multiple' appearance next
    if (aInMultiple && !bInMultiple) return -1;
    if (!aInMultiple && bInMultiple) return 1;
    
    // If neither in 'single' nor 'multiple', retain original order
    return 0;
  })

  return output
}