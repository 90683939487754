import request from '@/lib/request'
import { getHeaders } from '@/lib/cryptoJs'

// ** Configs
import authConfig from 'src/configs/auth'

interface IGoogleAuthParams {
  code: string
  token: string
}

const AuthService = () => {
  const accessToken = window.localStorage.getItem(authConfig.storageTokenKeyName)

  const googleAuthenticator = (data: IGoogleAuthParams) => {
    return request({
      headers: { Accept: 'application/json', Authorization: `Bearer ${data.token}`, ...getHeaders() },
      url: '/2fa/google/authenticator',
      method: 'POST',
      params: { code: data.code }
    })
  }

  const getUser2FA = (id: number) => {
    return request({
      headers: {
        ...getHeaders(),
        Authorization: `Bearer ${accessToken}`
      },
      url: `/users/${id}/google/2fa`,
      method: 'GET'
    })
  }

  const getMe = () => {
    return request({
      headers: {
        ...getHeaders(),
        Authorization: `Bearer ${accessToken}`
      },
      url: `/admin/me`,
      method: 'GET'
    })
  }

  return { googleAuthenticator, getUser2FA, getMe }
}

export default AuthService
