// ** MUI Imports
import { Theme } from '@mui/material/styles'

const inputLabel = (theme: Theme) => {
  return {
    MuiInputLabel: {
      styleOverrides: {
        root: {
          backgroundColor: 'transparent',
          color: theme.colors.primaryText
        }
      }
    },
  }
}

export default inputLabel
