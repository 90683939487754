// ** Icon Imports
import Icon from 'src/@core/components/icon'

// ** Third Party Import
import { useTranslation } from 'react-i18next'

// ** Custom Components Imports
import OptionsMenu from 'src/@core/components/option-menu'

// ** Type Import
import { Settings } from 'src/@core/context/settingsContext'

interface Props {
  settings: Settings
  saveSettings: (values: Settings) => void
}

const LanguageDropdown = ({ settings, saveSettings }: Props) => {
  // ** Hook
  const { i18n } = useTranslation()

  // ** Vars
  const { layout } = settings

  const handleLangItemClick = (lang: 'en' | 'fr' | 'ar' | 'zh_cn') => {
    i18n.changeLanguage(lang)
  }

  return (
    <OptionsMenu
      icon={<Icon icon='mdi:web' />}
      menuProps={{ sx: { '& .MuiMenu-paper': { mt: 4, minWidth: 130 } } }}
      iconButtonProps={{ color: 'inherit', sx: { ...(layout === 'vertical' ? { mr: 0.75 } : { mx: 0.75 }) } }}
      options={[
        {
          text: 'Chinese',
          menuItemProps: {
            sx: { py: 2 },
            selected: i18n.language === 'zh_cn',
            onClick: () => {
              handleLangItemClick('zh_cn')
              saveSettings({ ...settings, direction: 'ltr' })
            }
          }
        },
        {
          text: 'English',
          menuItemProps: {
            sx: { py: 2 },
            selected: i18n.language === 'en',
            onClick: () => {
              handleLangItemClick('en')
              saveSettings({ ...settings, direction: 'ltr' })
            }
          }
        }
        // {
        //   text: 'French',
        //   menuItemProps: {
        //     sx: { py: 2 },
        //     selected: i18n.language === 'fr',
        //     onClick: () => {
        //       handleLangItemClick('fr')
        //       saveSettings({ ...settings, direction: 'ltr' })
        //     }
        //   }
        // },
        // {
        //   text: 'Arabic',
        //   menuItemProps: {
        //     sx: { py: 2 },
        //     selected: i18n.language === 'ar',
        //     onClick: () => {
        //       handleLangItemClick('ar')
        //       saveSettings({ ...settings, direction: 'rtl' })
        //     }
        //   }
        // }
      ]}
    />
  )
}

export default LanguageDropdown
