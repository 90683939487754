// ** React Imports
import { createContext, useEffect, useState, ReactNode } from 'react'

// ** Next Import
import { useRouter } from 'next/router'

// ** Axios
import axios from 'axios'

// ** Config
import authConfig from 'src/configs/auth'

// ** Types
import { AuthValuesType, RegisterParams, LoginParams, ErrCallbackType, UserDataType } from './types'

import { getHeaders } from '@/lib/cryptoJs'

import { useAuth } from '@/services/useAuth'
import posthog from 'posthog-js'

// ** Defaults
const defaultProvider: AuthValuesType = {
  user: null,
  loading: true,
  setUser: () => null,
  setLoading: () => Boolean,
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve()
}

const AuthContext = createContext(defaultProvider)

type Props = {
  children: ReactNode
}

// ** Constant variables
const IS_SIT = process.env.NEXT_PUBLIC_APP_VARIANT === 'sit'
const baseUrl = IS_SIT ? process.env.NEXT_PUBLIC_API_BASE_URL_SIT : process.env.NEXT_PUBLIC_API_BASE_URL_LOCAL

const AuthProvider = ({ children }: Props) => {
  // ** States
  const [user, setUser] = useState<UserDataType | null>(defaultProvider.user)
  const [loading, setLoading] = useState<boolean>(defaultProvider.loading)
  const auth = useAuth()

  // ** Hooks
  const router = useRouter()

  useEffect(() => {
    const storedToken = window.localStorage.getItem(authConfig.storageTokenKeyName)
    const userData: string = window.localStorage.getItem(authConfig.userDataKeyname) || ''
    if (storedToken && userData) {
      const userDataObj: UserDataType = JSON.parse(userData)
      setUser(userDataObj)
      if (userDataObj.id && userDataObj.username) {
        posthog.identify(
          `Bf_admin-${userDataObj.id}_${userDataObj.username}`,
          { 
            trackId: `Bf_admin-${userDataObj.id}-${userDataObj.username}`,
            userId: `${userDataObj.id}`,
            username: userDataObj.username,
            role: userDataObj.role,
          }
        );
      }
    }
    setLoading(false)
  }, [])

  const handleLogin = (params: LoginParams, errorCallback?: ErrCallbackType) => {
    const newParams = {
      username: params.username, // Change key to `username`
      password: params.password
    }

    axios
      .post(`${baseUrl + authConfig.loginEndpoint}`, newParams, {
        headers: {
          ...getHeaders()
        }
      })
      .then(async response => {
        const tempUserData = {
          id: response.data.data.id,
          abilities: response.data.data.abilities,
          role: response.data.data.role,
          role_id: response.data.data.role_id,
          type: response.data.data.type,
          username: params.username,
          photo: response.data.data.photo,
          site: response.data.data.site ? response.data.data.site[0] : null,
          partner_id: response.data.data.partner_id ? response.data.data.partner_id : null,
          current_balance: response.data.data.current_balance || null,
          dummy: response.data.data.dummy
        }

        const returnUrl = router.query.returnUrl as string
        const redirectURL = returnUrl && returnUrl !== '/' ? returnUrl : '/'

        // ** If user is dummy, redirect to dashboard page, else redirect to 2fa page
        if (tempUserData.dummy) {
          const token = response.data.data.token
          
          // Create new cache
          window.localStorage.setItem(authConfig.userDataKeyname, JSON.stringify(tempUserData))
          window.localStorage.setItem(authConfig.storageTokenKeyName, token)

          // Set user context
          auth.setUser(tempUserData)

          // Go to redirect URL
          // router.replace(redirectURL as string)

          // I don't know why redirect to dashboard page by router.replace(redirectURL as string)
          // will hanging at the loading page and need to refresh the page to go to dashboard page
          // So I use window.location.replace to redirect to dashboard page
          // I think this is not the best solution, but it works
          // If you have any better solution, please change it
          // Thanks
          window.location.replace(redirectURL)

          return;
        }

        // setUser(tempUserData)
        params.rememberMe
          ? window.localStorage.setItem(authConfig.tempStorageTokenKeyName, response.data.data.token)
          : null
        params.rememberMe
          ? window.localStorage.setItem(authConfig.tempUserDataKeyname, JSON.stringify(tempUserData))
          : null

        const twoFactorAuthData = {
          qr2faGoogle: response.data.data['2fa-google'] || null,
          isAuthenticated: false
        }
        window.localStorage.setItem(authConfig.twoFADataKeyName, JSON.stringify(twoFactorAuthData))

        if (redirectURL !== '/') {
          router.replace({
            pathname: authConfig.twoFAEndpoint,
            query: { returnUrl: redirectURL }
          })
        } else {
          router.replace(authConfig.twoFAEndpoint)
        }
      })
      .catch(err => {
        console.log('Login error!', err)
        if (errorCallback) errorCallback(err)
      })
  }

  const handleLogout = () => {
    setUser(null)
    window.localStorage.removeItem(authConfig.userDataKeyname)
    window.localStorage.removeItem(authConfig.tempUserDataKeyname)
    window.localStorage.removeItem(authConfig.storageTokenKeyName)
    window.localStorage.removeItem(authConfig.tempStorageTokenKeyName)
    window.localStorage.removeItem(authConfig.twoFADataKeyName)
    router.push('/login')
  }

  const handleRegister = (params: RegisterParams, errorCallback?: ErrCallbackType) => {
    axios
      .post(authConfig.registerEndpoint, params)
      .then(res => {
        if (res.data.error) {
          if (errorCallback) errorCallback(res.data.error)
        } else {
          handleLogin({ username: params.username, password: params.password })
        }
      })
      .catch((err: { [key: string]: string }) => (errorCallback ? errorCallback(err) : null))
  }

  const values = {
    user,
    loading,
    setUser,
    setLoading,
    login: handleLogin,
    logout: handleLogout,
    register: handleRegister
  }

  return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>
}

export { AuthContext, AuthProvider }
